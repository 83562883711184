/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import NextLink from 'next/link'
import { useSearchParams } from 'next/navigation'
import { type ComponentPropsWithoutRef, useCallback, useEffect } from 'react'
import { type z } from 'zod'

import { buildHref } from './buildHref'
import { schema } from './TypedSchema'

type Schema<Path extends keyof typeof schema> = z.infer<(typeof schema)[Path]>

type SchemaKeys = keyof typeof schema

type TypedHrefArgs<Path extends SchemaKeys> =
  Schema<Path> extends never
    ? { path: Path; query?: undefined }
    : { path: Path; query: Schema<Path> }

export const buildTypedHref = <Path extends SchemaKeys>({
  path,
  query,
}: TypedHrefArgs<Path>): string => buildHref({ path, query: schema[path].parse(query) })

type TypedLinkProps<T extends SchemaKeys> = TypedHrefArgs<T> &
  Omit<ComponentPropsWithoutRef<typeof NextLink>, 'href'>

export default function TypedLink<Path extends SchemaKeys>({
  children,
  path,
  prefetch = false,
  query,
  ...props
}: TypedLinkProps<Path>) {
  return (
    <NextLink
      href={buildTypedHref({ path, query } as TypedHrefArgs<Path>)}
      prefetch={prefetch}
      {...props}
    >
      {children}
    </NextLink>
  )
}

export function typedPageParams<T extends SchemaKeys>(path: T) {
  return <S extends keyof Schema<T>>() => {
    const search = useSearchParams()
    useEffect(() => {
      if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
        const currentPath = window.location.pathname.replace(/\/*$/, '')
        if (currentPath !== path) {
          throw new Error(`Wrong page params used. Used ${path} on page ${currentPath}`)
        }
      }
    }, [])

    return [
      // Get all params
      schema[path].safeParse(Object.fromEntries([...search.entries()])).data as Schema<T>,
      // Update params
      useCallback((values: { [key in S]?: Schema<T>[key] }) => {
        const params = new URLSearchParams(location.search)
        Object.entries(values).forEach(([key, value]) => {
          if ((['', undefined, null] as unknown[]).includes(value)) {
            params.delete(key)
          } else {
            Object.entries(schema[path].safeParse(values).data ?? {}).forEach(([validKey]) => {
              if (key === validKey) {
                params.set(key, String(value))
              }
            })
          }
        })
        window.history.pushState(null, '', `?${params.toString()}`)
      }, []),
      // Clear non-mandatory params
      useCallback(() => {
        const params = new URLSearchParams(location.search)
        if ('shape' in schema[path]) {
          const mandatory = [...Object.entries(schema[path].shape)]
            .filter(([, value]) => !(value as z.ZodTypeAny).isOptional())
            .map(([key]) => key)
          const keysToDelete: string[] = []
          params.forEach((_, key) => {
            if (!mandatory.includes(key)) {
              keysToDelete.push(key)
            }
          })
          keysToDelete.forEach((key) => params.delete(key))
        }
        window.history.pushState(null, '', `?${params.toString()}`)
      }, []),
    ] as const
  }
}
